window.require = require
window.jQuery = window.$ = require('jquery')


var tri = require('tripartite')

/*
var templates = require('../static_content/templates/pages.tmpl')
Tripartite.parseTemplateScript(templates)
*/

// require('page-editor').configure()


$('.button-area a').click(function(evt) {
	evt.preventDefault()
	
	$('.contact-area').addClass('show-form')
	$('.contact-area input').eq(0).focus()
})

$("#landing-contact").submit(function(evt) {
	evt.preventDefault()
    var $this = $(this)
    $.ajax({
        type: "POST",
        url: '/contact',
        data: $('#landing-contact').serialize()
    }).done(function (data) {
        $('.contact-area').addClass('thanks')
    })	
	$('#landing-contact input').prop('disabled', true)
	$('#landing-contact textarea').prop('disabled', true)
	$('#landing-contact button').prop('disabled', true).html('Sending ...')
})


